require('jquery');
import 'webpack-jquery-ui/interactions';
// import 'webpack-jquery-ui/css';
import Sortable from 'packs/Sortable.min'
import WOW from 'packs/wow.min'


$( document ).on('turbolinks:load', function() {
    window.close_flash();
    window.custom_file_name();
    window.start_movable();
    window.start_sortable();
    window.save_button();
    window.start_parallax();
    window.reffering_input();
});

window.close_flash = () => $("body").on('click', ".close-flash", function(e) {
  e.preventDefault();
  return $(this).closest(".flash").slideUp("slow");
});

window.readURL = function(input_field) {
  if (input_field.files && input_field.files[0]) {
    const reader = new FileReader;

    reader.onload = function(e) {
      $(input_field).closest('.form-image-box-js').find('.js-profile-img-tag').attr('src', e.target.result);
    };

    reader.readAsDataURL(input_field.files[0]);
  }
};

window.avatar = () => $('.form-control-file').change(function() {
  window.readURL(this);
});

window.profile_form = () => $('.hidden-field-trigger-js').change(function() {
  $(this).closest('.hidden-field-cont-js').find('.hidden-field-js').toggleClass('hidden');
});

window.hidden_form_listener = () => $('.hidden-form-trigger-js').change(function() {
  // console.log("aba")
  $('.big-hidden-field-js').toggleClass('hidden');
  $('.form-grid').toggleClass('no-trigger');
});

window.custom_file_name = () => $('.file-upload').change(function() {
  var file_name = $('.file-upload')[0].files[0].name;
  $(this).closest('.form-group').find('label').text(file_name);
});

window.stop_user_select = () => $('body').addClass('no-user-select');

window.start_user_select = () => $('body').removeClass('no-user-select');

window.start_sortable = function(parent) {
  if (parent == null) { parent = ""; }
  const sortable_container = document.getElementsByClassName(`sortable-container ${parent}`);
  if (sortable_container) {
    return [].forEach.call(sortable_container, el => Sortable.create(el, {
      group: {
        name: 'movable',
        pull: true,
        put: true
      },
      sort: true,
      animation: 150,
      scroll: true,
      scrollSensitivity: 100,
      ghostClass: 'ghost',
      chosenClass: 'chosen-element',
      filter: 'input, textarea, .select2, .trumbowyg-box, .CodeMirror',
      preventOnFilter: false,
      forceFallback: true,

      onAdd(evt) {
        const itemEl = evt.item;
        // dragged HTMLElement
      },
      onStart(evt) {
        $(evt.to).addClass("list-with-bg");
        return window.stop_user_select();
      },

      onEnd(evt) {
        if (evt.to == $(".sortable-container")[0]){
          $(evt.to).removeClass("list-with-bg");
          $(".save-button").removeClass("hidden");

        } else if(evt.to == $(".movable-container")[0]) {
            $(".save-button").removeClass("hidden");
        }

        return window.start_user_select();
      }
    }
    ));
  }
};

window.start_movable = function() {
    const movable_element = document.getElementsByClassName('movable-container')[0];
    if (movable_element) {
      // $(movable_element).sortable();
      return Sortable.create(movable_element, {
        sort: false,
        group: {
          name: 'movable',
          pull: true,
          put: true
        },
        animation: 150,
        scroll: true,
        scrollSensitivity: 100,
        ghostClass: 'ghost',
        chosenClass: 'chosen-element',
        forceFallback: true,

        onStart(evt) {
          $('.challenge-sortable-container').addClass("list-with-bg");
          return window.stop_user_select();
        },

        onEnd(evt) {
          $('.challenge-sortable-container').removeClass("list-with-bg");
          if(evt.to == $(".sortable-container")[0]) {
            $(".save-button").removeClass("hidden");
          }
          return window.start_user_select();
        }
      }
      );
    }
  };

window.save_button = () => $("#save_button").on("click", function(e){
  e.preventDefault();
  const order = ["default"]; //ten default musi być, bo nie moze byc order = [], bo się nie wyśle jako paramert (jesli nic sie nie doda do tablicy) - patrz user.rb -> update_invitation / update_invitations_mentor i tam jest to usuwane
  $(".sortable-container").children("div.mentor").each(function () {
    order.push($(this).children(".user_info_js").data("userId"));
  });
  const current_user_id = $(".sortable-container").children("div.page_info_js").data("id");
  const page_type = $('.sortable-container').children("div.page_info_js").data("page");
  $.ajax({
    url: `/users/${current_user_id}/${page_type}_list`,
    type: "GET",
    dataType: 'script',
    data: {
      order: order
    }
  });
  //$(this).addClass("hidden");
})

window.start_parallax = function() {
  const wow = new WOW({
    offset: 200
  })
  wow.init();
}

window.toggleRefferingInput = function(){
  if($("#user_reffered").prop("checked")){
    $("#user_reffering_person").removeClass("hidden");
  } else {
    $("#user_reffering_person").addClass("hidden");
  }
}

window.reffering_input = function(){
  window.toggleRefferingInput()
  $("#user_reffered").change(function() {
    window.toggleRefferingInput()
  })
}
