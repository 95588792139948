require('jquery');
import 'webpack-jquery-ui/interactions';

$(document).on('turbolinks:load', function() {
    window.message_input();
    window.scroll_messages();
});

window.message_input = () => $(document).on('keypress', '[data-behavior~=room_speaker]',function(e){
  if(e.keyCode == 13){
    var message = e.target.value
    var chatroom = $("input#chatroom-id")[0].value
    var user = $("input#message-user")[0].value
    e.target.value = ''
    e.preventDefault()
    if(message.length > 0) {
      $.ajax({
        url: "/users/messages",
        type: "POST",
        dataType: 'script',
        data: {
          message: {
            chatroom_id: chatroom,
            user_id: user,
            message: message
          }
        }
      });
    }
  }
})

window.scroll_messages = function(){
  var element = document.querySelector(".messages");
  // console.log(element);
  if(element){
    element.scrollTop = element.scrollHeight
  }
}
