// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import select2 from 'select2/dist/js/select2'
// // import * as $ from 'jquery';
// // import * as select2 from "select2";
//
// import "select2/dist/css/select2.css";
// // import "select2-bootstrap-theme/dist/select2-bootstrap.css";
//
// // Hook up select2 to jquery
// // select2($);


import $ from 'jquery';
import 'select2/dist/js/select2.full';

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('jquery');
require('jquery-ui');

require("custom/custom")
require("custom/messages")

// require('webpack-jquery-ui');
// require('webpack-jquery-ui/css');
import 'popper.js';
import 'bootstrap';
import 'bootstrap-datepicker';
global.jQuery = $;
global.$ = $;

$( document ).on('turbolinks:load', function() {
  $('.datepicker').datepicker({

  });
  $('.select2-enable').select2({
    theme: "bootstrap",
    language: "en"
    // allow_clear: true
  });
  $('.select2-enable.country-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Select a country",
    allowClear: true
  });
  $('.select2-enable.corporate-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Select a corporate",
    allowClear: true
  });
  $('.select2-enable.gender-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Select a gender",
    allowClear: true
  });
  $('.select2-enable.roles-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Select a roles",
    allowClear: true
  });
  $('.select2-enable.mentor-choice-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "I want to be ...",
    allowClear: true
  });
  $('.select2-enable.experience-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "My professional experience (in years)",
    allowClear: true
  });
  $('.select2-enable.language-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Mentoring preferred language (select all)",
    allowClear: true
  });
  $('.select2-enable.current-level-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Current status",
    allowClear: true
  });

  $('.select2-enable.current-level-select').on("change", function(ev){
      if($(ev.target).val() == "10"){
        $("#user_current_status_other").removeClass("hidden");
      } else {
        $("#user_current_status_other").addClass("hidden");
      }
  })

  $('.select2-enable.area-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Choose all relevant",
    allowClear: true
  });

  $('.select2-enable.area-select').on("change", function(ev){
      if($(ev.target).val().includes("other")){
        $("#user_area_of_expertise_other").removeClass("hidden");
      } else {
        $("#user_area_of_expertise_other").addClass("hidden");
      }
  })

  $('.select2-enable.mentee-experience-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "I prefer to work with a mentee with professional experience of",
    allowClear: true
  });
  $('.select2-enable.mentee-company-size-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Preferable type (size) of the company of my mentee",
    allowClear: true
  });
  $('.select2-enable.industry-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Indicate as many industries as you like or choose.",
    allowClear: true
  });

  $('.select2-enable.mentor-industry-select').on("change", function(ev){
      if($(ev.target).val().includes("other")){
        $("#user_mentor_industries_other").removeClass("hidden");
      } else {
        $("#user_mentor_industries_other").addClass("hidden");
      }
  })

  $('.select2-enable.mentee-areas-select').select2({
    theme: "bootstrap",
    language: "en",
    // placeholder: "Select your mentoring objective (up to 5)",
    placeholder: "Select",
    allowClear: true
  });

  $('.select2-enable.mentee-areas-select').on("change", function(ev){
      if($(ev.target).val().includes("other")){
        $("#user_mentee_areas_other").removeClass("hidden");
      } else {
        $("#user_mentee_areas_other").addClass("hidden");
      }
  })

  $('.select2-enable.next-career-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "My next career/professional goal",
    allowClear: true
  });
  $('.select2-enable.mentor-experience-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Choose experience:",
    allowClear: true
  });
  $('.select2-enable.mentee-hopes-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "What I hope to gain from the mentoring:",
    allowClear: true
  });
  $('.select2-enable.mentor-company-size-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Choose size of the company:",
    allowClear: true
  });
  $('.select2-enable.work-with-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Do you prefer to work with:",
    allowClear: true
  });
  $('.select2-enable.education-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Select your field of study level",
    allowClear: true
  });
  $('.select2-enable.ideal-characteristics-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Choose characteristics:",
    allowClear: true
  });

  $('.select2-enable.ideal-characteristics-select').on("change", function(ev){
      if($(ev.target).val().includes("other")){
        $("#user_ideal_mentor_characteristics_other").removeClass("hidden");
      } else {
        $("#user_ideal_mentor_characteristics_other").addClass("hidden");
      }
  })

  $('.select2-enable.my-characteristics-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Which characteristics do you have as a mentor (select up to 7)",
    allowClear: true
  });

  $('.select2-enable.my-characteristics-select').on("change", function(ev){
      if($(ev.target).val().includes("other")){
        $("#user_my_characteristics_other").removeClass("hidden");
      } else {
        $("#user_my_characteristics_other").addClass("hidden");
      }
  })

  $('.select2-enable.mentor-past-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Have you acted as an official Mentor in the past, through your organisation or otherwise?",
    allowClear: true
  });
  $('.select2-enable.meetings-select').select2({
    theme: "bootstrap",
    language: "en",
    placeholder: "Choose option",
    allowClear: true
  });
})



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
